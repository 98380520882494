<template>
  <CCard>
    <CCardBody>
      <CRow class="justify-content-between align-items-center mb-3">
        <CCol>
          <div class="d-flex align-items-center">
            <figure v-if="marketHaveLogo(market)" class="m-0 mr-3">
              <img
                :src="marketLogoThumb(market)"
                alt=""
                class="border rounded-circle"
                width="64"
                height="64"
              />
            </figure>
            <div>
              <div class="ui-type-caption text-uppercase">
                {{ $t('family.market.box.title') }}
              </div>
              <div class="d-flex align-items-center">
                <span class="d-inline-block ui-type-display-lg mr-3">{{
                  marketName(market)
                }}</span>
                <span
                  class="badge badge-success mr-2"
                  v-if="showDelivery(market)"
                >
                  <font-awesome-icon icon="truck" /> {{ $t('family.market.box.delivery') }}</span
                >
                <span class="badge badge-light" v-if="showPickup(market)">
                  <font-awesome-icon icon="store" /> {{ $t('family.market.box.pickup') }}</span
                >
              </div>
              <div v-html="market.market.role.desc_short"></div>
            </div>
          </div>
        </CCol>
        <CCol col="auto" class="ml-auto text-right">
          <div class="mb-2">{{ market.market.role.desc_zone }}</div>
          <CButton color="primary" size="sm" @click="openMarket(market)"
            >{{ $t('family.market.box.btn') }}</CButton
          >
        </CCol>
      </CRow>

      <div>
        <div class="d-flex justify-content-center" v-if="loading">
          <CSpinner color="info" />
        </div>

        <flickity
          :options="flickityOptions"
          class="flickity-products"
          v-if="products.length > 0"
        >
          <CCol
            class="flickity-col"
            v-for="product in products"
            :key="product.id"
          >
            <MarketProductCard v-bind="product" @onClick="onCardClick" />
          </CCol>
        </flickity>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import { Query, Predicate } from "@syncfusion/ej2-data";
import MarketProductCard from "./MarketProductCard";
import { getProductCardDataFromMarketProduct } from "../../../helpers/common";
import { mapGetters } from "vuex";
import { getPriceUnitOptions } from "../../../helpers/options";
import marketMixin from "../../../mixins/market";
import { ApiCall, GetDataManagerNew } from "../../../ds";

export default {
  name: "MarketBox",

  mixins: [marketMixin],

  components: {
    MarketProductCard,
  },

  props: {
    market: { type: Object, required: true },
  },

  data() {
    return {
      loading: true,
      products: [],
      flickityOptions: {
        imagesLoaded: true,
        pageDots: false,
        cellAlign: "left",
        groupCells: true,
      },
    };
  },

  mounted() {
    this.loadProducts();
  },

  computed: {
    user_lang() {
      return this.$i18n.locale
    },
    ...mapGetters("connections", ["currencySymbol", "familyId"]),
  },

  watch: {
    // whenever question changes, this function will run
    user_lang(new_lang, old_lang) {
      if (new_lang!=old_lang) {
        this.products=[];
        this.loadProducts();
      }
    }
  },

  methods: {
    openMarket(market) {
      this.$store.commit("shop/setMarket", market);
      this.$store.commit("shop/setCart", {});
      this.$router.push({
        name: "UserMarketDetail",
        params: { familyId: this.familyId, marketId: market.market.id },
      });
    },

    loadProducts() {
      const self = this;
      const marketId = this.market.market.id;
      const familyId = this.$store.state.connections.current.family.id;
      const lang = this.$i18n;//.locale;
      const currencySymbol = this.currencySymbol;
      const unitOptions = getPriceUnitOptions(this.$i18n);

      const dm = GetDataManagerNew("family_market_products", [
        familyId,
        marketId,
      ]);

      this.loading = true;

      let query = new Query().take(10);
      let predicate = new Predicate("id", "greaterthanorequal", 0);

      query.where(predicate);

      ApiCall(
        dm,
        query,
        (e) => {
          self.products = e.result.map((product) => {
            return getProductCardDataFromMarketProduct(
              product,
              lang,
              currencySymbol,
              unitOptions
            );
          });
          self.loading = false;
        },
        (e) => {
          self.loading = false;
        }
      );
    },

    onCardClick(productId) {
      const familyId = this.$route.params.familyId;
      const marketId = this.market.market.id;
      this.$router.push({
        name: "UserMarketProduct",
        params: { familyId, marketId, productId },
      });
    },
  },
};
</script>
